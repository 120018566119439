import type { TournamentType, Tournament, TournamentStatus } from '../types'
import { useEnabledTournaments } from './useEnabledTournaments'

export interface PageTournamentReturnType {
  tournament: Ref<Tournament | null>
}

export function usePageTournament(
  type?: TournamentType | Ref<TournamentType> | (() => TournamentType),
): PageTournamentReturnType {
  const { locale } = useI18n()

  const tournamentType = toValue(type)
  const allTypes: TournamentType[] = ['all', 'bookmaker', 'cyber', 'casino']
  const { data: tournament, execute: fetchTournament } = useStFetch(
    '/rat-race/find',
    {
      method: 'post',
      body: computed(() => ({
        params: {
          language: locale.value as any,
          type: tournamentType ? [tournamentType] : allTypes,
          status: ['open'] as TournamentStatus[],
        },
        pagination: {
          page: 0,
          perPage: 1,
          orderBy: [{ fieldName: 'createdAt', sortOrder: 'DESC' } as const],
        },
      })),
      transform: (response) => response.data[0] as Tournament,
      immediate: false,
      watch: false,
    },
  )

  const { isEnabledTournaments } = useEnabledTournaments()

  watch(
    () => isEnabledTournaments.value,
    (newValue) => {
      if (newValue) {
        fetchTournament()
      }
    },
    {
      immediate: true,
    },
  )

  return {
    tournament,
  }
}
